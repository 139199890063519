.heading {
  font-family: "Noto IKEA";
  font-size: 24px;
  text-align: center;
  color: #111111;
}

.containerDiv {
  display: flex;
  align-items: center;
  vertical-align: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 25vh;
}

.submitBtn {
  margin-top: 15px;
  color: #ffffff !important;
  background-color: #0058a3 !important;
  border: #ffffff;
  width: 230px;
  height: 50px;
  border-radius: 50px;
  font-family: "Noto IKEA";
  font-size: 14px;
}
