.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  margin-top: 10vh;
}

.noDataContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 30vh;
}

.homeBtn {
  margin-top: 15px;
  color: #ffffff !important;
  background-color: #0058a3 !important;
  border: #ffffff;
  width: 150px;
  height: 50px;
  border-radius: 50px;
  font-family: "Noto IKEA";
  font-size: 14px;
}

.accordionTitle {
  font-family: "Noto IKEA";
  font-size: 16px;
  text-align: left;
  color: #111111;
  margin-left: 12px;
}
