.accordionTitle {
  font-family: "Noto IKEA";
  font-size: 16px;
  text-align: left;
  color: #111111;
  margin-left: 12px;
}

.accordionIcon {
  display: flex;
  height: 16px;
  left: 1px;
  top: 4px;
}
