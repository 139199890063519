.ag-header-cell-text {
  font-family: "Noto IKEA" !important;
  font-size: 16px !important;
  line-height: 18px !important;
  color: #484848;
  font-weight: normal;
}

.ag-theme-alpine .ag-floating-filter-button-button {
  display: none;
}

.ag-header-container {
  background-color: white;
}

.ag-theme-alpine .ag-root-wrapper {
  border: 0px !important;
}

.ui-slider-handle {
  z-index: 0 !important;
}

.label-wrapper--text-input label {
  display: none !important;
}

.ag-theme-alpine {
  font-family: "Noto IKEA" !important;
}

html,
body {
  font-family: "Noto IKEA" !important;
}

.ag-theme-alpine .ag-row {
  font-size: 15px !important;
}

.example-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#myGrid {
  flex: 1 1 0px;
  width: 100%;
}

.ag-input-field-input {
  padding: 5px !important;
}

.checkbox-group .checkbox:not(:last-child){
  margin-bottom: 0.75rem !important;
}

.btn__inner{
  padding: 0.75rem !important;
  height: 2.3rem !important;
  min-height: 0.9rem !important;
}