.tableHeader {
  height: 42px;
  width: 331px;
  border-radius: 0px;
  padding: 12px, 0px, 0px, 0px;
}

.tableBody {
  height: 43px;
  width: 331px;
  border-radius: 0px;
  padding: 10px, 0px, 0px, 0px;
}

.headerData {
  font-family: "Noto IKEA";
  font-size: 12px;
  text-align: left;
  color: #484848;
}

.tableData {
  font-family: "Noto IKEA";
  font-size: 16px;
  text-align: left;
}
