.actionsButton {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTcgMTMuNUM2LjE3MTU3IDEzLjUgNS41IDEyLjgyODQgNS41IDEyQzUuNSAxMS4xNzE2IDYuMTcxNTcgMTAuNSA3IDEwLjVDNy44Mjg0MyAxMC41IDguNSAxMS4xNzE2IDguNSAxMkM4LjUgMTIuODI4NCA3LjgyODQzIDEzLjUgNyAxMy41Wk0xMiAxMy41QzExLjE3MTYgMTMuNSAxMC41IDEyLjgyODQgMTAuNSAxMkMxMC41IDExLjE3MTYgMTEuMTcxNiAxMC41IDEyIDEwLjVDMTIuODI4NCAxMC41IDEzLjUgMTEuMTcxNiAxMy41IDEyQzEzLjUgMTIuODI4NCAxMi44Mjg0IDEzLjUgMTIgMTMuNVpNMTcgMTMuNUMxNi4xNzE2IDEzLjUgMTUuNSAxMi44Mjg0IDE1LjUgMTJDMTUuNSAxMS4xNzE2IDE2LjE3MTYgMTAuNSAxNyAxMC41QzE3LjgyODQgMTAuNSAxOC41IDExLjE3MTYgMTguNSAxMkMxOC41IDEyLjgyODQgMTcuODI4NCAxMy41IDE3IDEzLjVaIiBmaWxsPSJjdXJyZW50Q29sb3IiLz48L3N2Zz4=);
  cursor: pointer;
  border: none;
  background-repeat: no-repeat;
  background-size: contain;
  width: 1.5rem;
  height: 1.5rem;
  margin-top: 10px;
  background-color: white;
}

.menuContainer {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  position: absolute;
  z-index: 1000;
  padding: 0.4rem 0;
  margin: 0;
  font-size: 1rem;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.menuItem:hover {
  cursor: pointer;
  background: rgb(240, 240, 240);
}

.menuItem.active {
  background-color: #dc3545;
  border-color: #dc3545;
  color: white;
}
.menuItem {
  display: block;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  border: 0;
}
