.detailsPageContainer {
  position: absolute;
  height: 1008px;
  left: 128px;
  right: 128px;
  top: 64px;
  background: #ffffff;
}

.link {
  display: inline;
  font-family: "Noto IKEA";
  font-size: 12px;
  margin-top: 15px;
  text-align: left;
  color: #0e12ee;
  margin-right: 0.5%;
  text-decoration: none;
}

.title {
  font-family: "Noto IKEA";
  font-size: 36px;
  margin-bottom: 0%;
  color: #111111;
  margin-top: 24px;
}

.description {
  font-family: "Noto IKEA";
  font-size: 16px;
  margin-bottom: 2%;
  margin-top: 0%;
}

.kciDetailsName {
  font-family: "Noto IKEA";
  font-size: 14px;
  margin-bottom: 5%;
}

.kciDetailsValue {
  font-family: "Noto IKEA";
  font-size: 16px;
}

.divContainer {
  display: flex;
  margin-bottom: 2.5%;
}

.divBlocks {
  display: inline;
  margin-right: 128px;
  margin-right: auto;
}
