.selectFilter {
  min-height: 24px;
  flex: 1 1 auto;
  padding: 5px;
  border: 1px solid #babfc7;
  width: 100%;
  font-size: 14px;
  border-radius: 3px;
}

.selectFilter:focus {
  outline: none;
  border: 1px solid rgba(33, 150, 243, 0.4);
}
