/* latin-ext */
@font-face {
  font-family: "Noto IKEA";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://www.ikea.com/global/assets/fonts/woff2/noto-ikea-400i.latin-ext.7ed5b4fc.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Noto IKEA";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://www.ikea.com/global/assets/fonts/woff2/noto-ikea-400i.latin.a15d672b.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: "Noto IKEA";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://www.ikea.com/global/assets/fonts/woff2/noto-ikea-700i.latin-ext.c6db488c.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Noto IKEA";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://www.ikea.com/global/assets/fonts/woff2/noto-ikea-700i.latin.f77e6ad9.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: "Noto IKEA";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://www.ikea.com/global/assets/fonts/woff2/noto-ikea-400.latin-ext.14a7297a.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Noto IKEA";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://www.ikea.com/global/assets/fonts/woff2/noto-ikea-400.latin.16880ce8.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: "Noto IKEA";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://www.ikea.com/global/assets/fonts/woff2/noto-ikea-700.latin-ext.3934340f.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Noto IKEA";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://www.ikea.com/global/assets/fonts/woff2/noto-ikea-700.latin.5d09a799.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

:root {
  --ikea-font: "Noto IKEA", "Noto Sans", "Roboto", "Open Sans", system-ui,
    sans-serif !important;
  font-family: var(--ikea-font);
}
